import React from "react";
import { graphql } from "gatsby";
import { ProductListingPage } from "../components/product-listing";

export default function CollectionTemplate (props) {
	return <ProductListingPage {...props} />
}

export const query = graphql`
  query($handle: String!) {
		collection: shopifyCollection(handle: { eq: $handle }) {
			...CollectionPage
		}
  }
`

export { CollectionMeta as Head } from "../components/meta"